import ExecuteApiCall from './apiClient';

export function sendEmailOtpRequest(payload) {
  return ExecuteApiCall('put', 'api/v3/users/resend_2fa_otp', payload);
}

export function validateOtp(payload) {
  return ExecuteApiCall('post', 'api/v3/users/two_fa_validate_otp', payload);
}

export function reset2FA(payload) {
  return ExecuteApiCall('post', 'api/v3/users/two_fa_reset', payload);
}

export function getQrCode(payload) {
  return ExecuteApiCall('post', 'api/v3/users/two_fa_qr_code', payload);
}

import React from 'react';
import PropTypes from 'prop-types';
import Images from '~/utils/ImageStore';
import Style from './AuthChoice.scss';

const AuthChoice = ({ name, description, image }) => (
  <div className={Style.AuthChoice}>
    <div className={Style.image}>
      {image &&
        <img src={image} alt="auth" />}
    </div>
    <div className={Style.details}>
      <div className={Style.name}>
        {name}
      </div>
      <div className={Style.description}>
        {description}
      </div>
    </div>
    <div className={Style.arrow}>
      <img src={Images.RightChevron} alt="arrow" />
    </div>
  </div>
);

AuthChoice.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default AuthChoice;

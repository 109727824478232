import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import MainStore from '~/redux/store/MainStore';
import retrieveRegion from '~/utils/region';
import Choose2FA from './components/Choose2FA/Choose2FA';

window.retrieveRegion = retrieveRegion;

const enter2FARoot = document.getElementById('enter_2fa_container');

if (enter2FARoot) {
  const root = createRoot(enter2FARoot);

  const email = enter2FARoot.dataset.email;
  root.render(
    <Provider store={MainStore}>
      <Choose2FA email={email} />
    </Provider>,
  );
}

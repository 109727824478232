// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".AuthChoice_AuthChoice{display:-webkit-box;display:-ms-flexbox;display:flex;text-align:start;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.AuthChoice_AuthChoice .AuthChoice_image{-webkit-margin-end:10px;margin-inline-end:10px}.AuthChoice_AuthChoice .AuthChoice_details{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}.AuthChoice_AuthChoice .AuthChoice_details .AuthChoice_name{font-weight:700}.AuthChoice_AuthChoice .AuthChoice_details .AuthChoice_description{line-height:14px}.AuthChoice_AuthChoice .AuthChoice_arrow{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;-webkit-margin-start:4px;margin-inline-start:4px}html[dir=rtl] .AuthChoice_AuthChoice .AuthChoice_arrow{-webkit-transform:scaleX(-1);transform:scaleX(-1)}", "",{"version":3,"sources":["AuthChoice.scss"],"names":[],"mappings":"AAAA,uBACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,gBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,yCACE,uBAAA,CAAA,sBAAA,CAEF,2CACE,kBAAA,CAAA,mBAAA,CAAA,WAAA,CACA,4DACE,eAAA,CAEF,mEACE,gBAAA,CAGJ,yCACE,kBAAA,CAAA,mBAAA,CAAA,WAAA,CACA,wBAAA,CAAA,uBAAA,CAMA,uDACE,4BAAA,CAAA,oBAAA","file":"AuthChoice.scss","sourcesContent":[".AuthChoice {\n  display: flex;\n  text-align: start;\n  align-items: center;\n  .image {\n    margin-inline-end: 10px;\n  }\n  .details {\n    flex-grow: 1;\n    .name {\n      font-weight: 700;\n    }\n    .description {\n      line-height: 14px;\n    }\n  }\n  .arrow {\n    flex-grow: 1;\n    margin-inline-start: 4px;\n  }\n}\n\nhtml[dir='rtl'] {\n  .AuthChoice {\n    .arrow {\n      transform: scaleX(-1)\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"AuthChoice": "AuthChoice_AuthChoice",
	"image": "AuthChoice_image",
	"details": "AuthChoice_details",
	"name": "AuthChoice_name",
	"description": "AuthChoice_description",
	"arrow": "AuthChoice_arrow"
};
module.exports = exports;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { Button, TextInput, Checkbox, Alert, Passcode } from '@glooko/common-ui';
import classNames from 'classnames';
import { translate } from '~/bundles/shared/components/WithTranslate/WithTranslate.jsx';
import Images from '~/utils/ImageStore';
import { sendEmailOtpRequest, validateOtp, getQrCode } from '~/services/twofactorApi';
import throwIfStatusCodeNotExpected from '~/utils/throwIfStatusCodeNotExpected';
import { PATH_ROOT, PATH_SIGN_IN } from '~/bundles/shared/constants/navigation';
import Spinner from '~/bundles/shared/components/Spinner/Spinner';
import AuthChoice from './AuthChoice/AuthChoice';
import Style from './Choose2FA.scss';
import TileList from '../../../shared/components/TileList/TileList';

export const Choose2FA = (props) => {
  const [choice, setChoice] = useState('');
  const [emailSentOnce, setEmailSentOnce] = useState(false);
  const [passcode, setPasscode] = useState('');
  const [authAppRequestedOnce, setAuthAppRequestedOnce] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState();
  const [notice, setNotice] = useState();
  const [authAppUri, setAuthAppUri] = useState();
  const [authAppSecretKey, setAuthAppSecretKey] = useState();

  const {
    t,
    email,
  } = props;

  const isContinueEnabled = () => (passcode && passcode.length === 6);

  const authenticatorClicked = () => {
    if (!authAppRequestedOnce) {
      fetchAppQrCode();
    }

    setChoice('app');
    setAuthAppRequestedOnce(true);
  };

  const emailClicked = () => {
    if (!emailSentOnce) {
      sendOtpEmail();
    }

    setChoice('email');
    setEmailSentOnce(true);
  };

  const tilesWithImages = [
    {
      id: 'app',
      className: Style.choiceItem,
      isTileClickable: true,
      onClick: authenticatorClicked,
      content:
        (<AuthChoice
          name={t('choiceApp')}
          description={t('choiceAppDescription')}
          image={Images.mobilePhone}
        />),
    },
    {
      id: 'email',
      className: Style.choiceItem,
      isTileClickable: true,
      onClick: emailClicked,
      content:
        (<AuthChoice
          name={t('choiceEmail')}
          description={t('choiceEmailDescription')}
          image={Images.emailNotification}
        />),
    },
  ];

  const backClicked = () => {
    setChoice(null);
    setPasscode('');
    setError(null);
    setNotice(null);
  };

  const continueClicked = () => {
    if (isContinueEnabled()) {
      validateOtpRequest();
    }
  };

  const passcodeChanged = (value) => {
    setPasscode(value);
    setError(false);
    setNotice('');
  };

  const dontAskAgain = (e) => {
    setRememberMe(e.target.checked);
  };

  const resendEmailClicked = () => {
    sendOtpEmail(true);
  };

  const isValidKey = (value) => !isNaN(value);

  const sendOtpEmail = (resend) => {
    sendEmailOtpRequest({ email })
      .then(throwIfStatusCodeNotExpected(200))
      .then(() => {
        setError(false);
        setNotice(resend ? t('resendOtpEmailSuccess') : '');
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line
      });
  };

  const fetchAppQrCode = () => {
    getQrCode({})
      .then(throwIfStatusCodeNotExpected(200))
      .then((response) => {
        setAuthAppUri(response.data.qr_provider_uri);
        setAuthAppSecretKey(response.data.secret_key);
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line
      });
  };

  const validateOtpRequest = () => {
    const deliveryMethod = choice === 'app' ? 'app_otp' : 'email_otp';
    validateOtp({
      email,
      otpAttempt: passcode,
      deliveryMethod,
      rememberMe: rememberMe,
    })
      .then(() => {
        window.location.assign(PATH_ROOT);
      })
      .catch((e) => {
        setError(true);
        setNotice(e.response.data.error);
      });
  };

  const renderPasscode = () => (
    <div>
      <div className={Style.passcode}>
        <Passcode
          label={t('enterPassword')}
          isValidKey={isValidKey}
          onChange={passcodeChanged}
        />
        {notice && renderNotice()}
      </div>
      <div className={Style.links}>
        {choice === 'email' &&
          <Button
            className={Style.resendOtpButton}
            onClick={resendEmailClicked}
            variation='link'
            dataAttributes={{ testid: 'choose-2fa-resend-otp' }}
          >
            {t('resendOtpEmail')}
          </Button>}
      </div>
      <div className={Style.checkboxOuter}>
        <Checkbox
          className={Style.dontAskAgain}
          label={t('dontAskAgain')}
          onChange={dontAskAgain}
          dataAttributes={{ testid: 'choose-2fa-dont-ask-again' }}
        />
      </div>
    </div>
  );

  const renderNotice = () => (
    <Alert severity={error ? 'error' : 'info'} className={classNames(Style.Notice, { [Style.error]: error })}>
      {notice}
    </Alert>
  );

  const renderBackContinue = () => (
    <div className={Style.buttons}>
      <Button
        className={Style.buttonBack}
        variation='secondary'
        onClick={backClicked}
        dataAttributes={{ testid: 'choose-2fa-back' }}
      >
        {t('back')}
      </Button>
      <Button
        className={Style.buttonContinue}
        disabled={!isContinueEnabled()}
        onClick={continueClicked}
        dataAttributes={{ testid: 'choose-2fa-continue' }}
      >
        {t('continue')}
      </Button>
    </div>
  );

  if (choice === 'app') {
    return (
      <div className={Style.Choose2FA}>
        <div className={Style.title}>{t('choiceTitle')}</div>
        <div className={Style.subtitle}>
          <div className={Style.subtitleText}>{t('useQrCodeOrKey')}</div>
        </div>
        <div className={Style.twofaAuthAppSection}>
          <div className={Style.twofaAuthAppColumn}>
            <div className={Style.twofaAuthAppHeader}>{t('qrCode')}</div>
            <div className={Style.qrCode}>
              {!authAppUri ?
                (<div className={Style.busyLoading}><Spinner /></div>) :
                <QRCode
                  value={authAppUri}
                  renderAs="svg"
                  size={156}
                />}
            </div>
          </div>
          <div className={Style.twofaAuthAppColumn}>
            <div className={Style.twofaAuthAppHeader}>{t('secretKey')}</div>
            <TextInput
              type="text"
              key={authAppSecretKey}
              value={authAppSecretKey}
              disabled
              dataAttributes={{ testid: 'choose-2fa-auth-secret-key' }}
            />
          </div>
        </div>
        {renderPasscode()}
        <div className={Style.buttonFooter}>
          {renderBackContinue()}
        </div>
      </div>
    );
  }

  if (choice === 'email') {
    return (
      <div className={Style.Choose2FA}>
        <div className={Style.title}>{t('choiceTitle')}</div>
        <div className={Style.subtitleNarrow}>
          <div className={Style.subtitleText}>{t('enterPasswordEmail')}</div>
        </div>
        {renderPasscode()}
        <div className={Style.buttonFooter}>
          {renderBackContinue()}
        </div>
      </div>
    );
  }

  return (
    <div className={Style.Choose2FA}>
      <div className={Style.title}>{t('selectTitle')}</div>
      <div className={Style.subtitle}>
        <div className={Style.subtitleText}>{t('selectSubTitle')}</div>
        <div className={Style.subtitleText}>
          <span>{t('alreadyHaveAccount')}</span>
          <a className={Style.loginLink} href={PATH_SIGN_IN}> {t('login')}</a>
        </div>
      </div>
      <div className={Style.choiceOuter}>
        <div className={Style.choicesHeader}>
          {t('chooseOne')}
        </div>
        <div className={Style.choices}>
          <TileList
            tiles={tilesWithImages}
          />
        </div>
      </div>
    </div>
  );
};

Choose2FA.propTypes = {
  email: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export const Choose2FATranslated = translate('Choose2FA')(Choose2FA);

export default Choose2FATranslated;

import hash256 from './hash256';
import ExecuteApiCall from '../services/apiClient';

const retrieveRegion = (email, successCallback, failureCallback) => {
  const hashedEmail = hash256(email);

  ExecuteApiCall('get', 'api/v2/users/region', { emailHash: hashedEmail })
    .then((response) => {
      if (!successCallback) return;
      successCallback(response.data);
    })
    .catch((error) => {
      if (!failureCallback) return;
      failureCallback(error.response);
    });
};

export default retrieveRegion;
